// _loading-screen.scss
@keyframes progress {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}

// These variables can be customized
$background-color: #475569; // slate-600
$progress-bar-bg: rgba(255, 255, 255, 0.3);
$progress-bar-active: #2dd4bf; // teal-400
$text-color: #facc15; // yellow-400

.loading-screen {
  background-color: $background-color;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__progress-container {
    width: 20rem;
    position: relative;
  }

  &__progress-bar {
    height: 0.25rem;
    width: 100%;
    background-color: $progress-bar-bg;
    border-radius: 9999px;

    &--active {
      height: 100%;
      width: 50%;
      background-color: $progress-bar-active;
      border-radius: 9999px;
      animation: progress 2s ease-in-out infinite;
    }
  }

  &__percentage {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 0.875rem;
  }

  &__status {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: $text-color;
    font-size: 0.875rem;
  }
}
