.button-container {
  text-align: center;
  width: 100%;
}

.auth-form {
  .error {
    font-size: 0.7rem;
  }
}

h2 {
  .MuiCircularProgress-root {
    top: 1px;
    position: relative;
  }
}

.form-checkbox {
  margin-bottom: 1rem;

  label {
    display: flex;
    align-items: center;
    font-size: 0.7rem;

    input[type='checkbox'] {
      margin-right: 0.5rem;
      accent-color: #007bff;
      width: 1rem;
    }
  }
}

#sign-in-page,
#sign-up-page,
.account-form-page {
  height: 100vh;
  background-color: $st-dark-grey;

  footer {
    position: absolute;
    bottom: 0;
  }
}

#sign-up-form,
#sign-in-form,
.account-form {
  width: 300px;
  margin: 0 auto;
  background-color: $white;
  position: relative;
  top: 200px;
  padding: 20px;
  border-radius: 4px;

  .input-container {
    margin-bottom: 1rem;
  }

  input {
    padding-left: 0px;
    padding-right: 0px;
  }

  .forgot-password {
    padding-top: 0.5rem;
    font-size: 10px;
    text-decoration: underline;
  }

  #sign-up-link {
    text-align: center;
    width: 100%;
    a {
      color: $c-link-green;
    }
  }

  // New styles for the sign-in section
  .h-rule {
    margin: 1.5rem 0;
    hr {
      border: 0;
      border-top: 1px solid $input-border;
    }
  }

  .sign-in-link {
    text-align: center;
    font-size: 0.875rem;
    color: $st-dark-grey;

    button {
      background: none;
      border: none;
      padding: 0;
      font-size: inherit;
      color: $c-link-green;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        color: darken($c-link-green, 10%);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba($c-link-green, 0.2);
        border-radius: 2px;
      }
    }
  }

  .social-login-container {
    margin-top: 1rem;
    text-align: center;
  }
}

.uploader {
  border-radius: 4px;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.dropzone {
  padding: 2.5rem;
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  background-color: #f7fafc;
  transition: all 0.2s ease;

  &:hover,
  &.active {
    border-color: #4299e1;
    background-color: #ebf8ff;
  }

  svg {
    color: #4299e1;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    color: #4a5568;
    margin: 0.5rem 0;

    &:last-child {
      font-size: 0.875rem;
      color: #718096;
    }
  }
  &.max-files {
    border-color: #cbd5e0;
    background-color: #edf2f7;
    cursor: not-allowed;
    opacity: 0.7;

    &:hover {
      border-color: #cbd5e0;
      background-color: #edf2f7;
    }

    svg {
      color: #a0aec0;
    }

    p {
      color: #718096;
    }
  }

  &.file-rejected {
    border-color: #fc8181;
    background-color: #fff5f5;
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: #f3f4f6;
    border-color: #d1d5db;

    svg {
      color: #9ca3af;
    }

    p {
      color: #6b7280;
    }
  }
}

#uploader-file-container {
  margin-top: 1rem;
  text-align: left;
}

.dropzone.active {
  border-color: #66cc66;
}

.file-previews img {
  max-width: 100px;
  margin-top: 10px;
}

.quote-upload-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.5rem;
}

.file-actions {
  margin-left: auto;
  display: flex;
  position: relative;
}

.action-menu-container {
  position: relative;
}

.file-count {
  font-size: 0.875rem;
  color: #718096;
  margin-bottom: 1.5rem;
}
.max-files-text {
  color: #e53e3e;
  font-size: 0.875rem;
  font-weight: 500;
}

input {
  width: 100%;
  padding: 0.5rem;
  font-size: 16px;
  border: 1px solid $input-border;
  border-radius: 4px;
}

.input-container p.error {
  font-size: 12px;
  margin-top: 0.1rem;
}

.file-preview-container {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0.875rem 1rem;
  position: relative;
  margin-bottom: 0.75rem;
  background: #fff;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:hover {
    border-color: #d0d0d0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }

  .file-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    min-width: 0;
    max-width: calc(100% - 300px); // Reserve space for subcontractor input
    margin-right: 1rem;
    position: relative;

    svg {
      flex-shrink: 0;
      color: #2c5282;
      font-size: 1.25rem;
    }

    .filename-container {
      position: relative;
      min-width: 0;

      &:hover .filename-tooltip {
        opacity: 1;
        visibility: visible;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        color: #2d3748;
        font-size: 0.9375rem;
      }

      .filename-tooltip {
        position: absolute;
        bottom: calc(100% + 5px);
        left: 0;
        background: #2d3748;
        color: white;
        padding: 0.5rem 0.75rem;
        border-radius: 4px;
        font-size: 0.875rem;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease;
        white-space: nowrap;
        z-index: 10;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 15px;
          border: 6px solid transparent;
          border-top-color: #2d3748;
        }
      }
    }
  }
  .file-type-badge {
    background: #ebf4ff;
    color: #2c5282;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    white-space: nowrap;
    margin-left: auto;
    margin-right: 1rem;
    letter-spacing: 0.025em;
  }

  .subcontractor-input {
    width: 200px;
    flex-shrink: 0;
    margin-left: auto;
    position: absolute;
    right: 40px;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 0.875rem;
    transition: all 0.2s ease;

    &::placeholder {
      color: #a0aec0;
    }

    &:focus {
      outline: none;
      border-color: #4299e1;
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
    }

    &.overflow {
      border-color: #ed8936;
      background-color: #fffaf0;

      &:focus {
        border-color: #ed8936;
        box-shadow: 0 0 0 3px rgba(237, 137, 54, 0.15);
      }
    }
  }

  .remove-file-icon {
    cursor: pointer;
    color: #718096;
    transition: all 0.2s ease;
    flex-shrink: 0;
    margin-left: auto;
    padding: 0.25rem;
    border-radius: 4px;

    &:hover {
      color: #e53e3e;
      background-color: #fff5f5;
    }
  }

  &.file-error {
    background-color: #fff5f5;
    border-color: #feb2b2;

    .error {
      color: #c53030;
      font-size: 0.875rem;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        color: #c53030;
      }
    }
  }
}

.max-files-warning {
  background-color: #fff8eb;
  border: 1px solid #ffd79d;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  svg {
    color: #d97706;
    flex-shrink: 0;
  }

  p {
    margin: 0;
    color: #92400e;
    font-size: 0.875rem;
  }
}

.invalid-file-alert {
  background-color: #fff5f5;
  border: 1px solid #fc8181;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  animation: fadeIn 0.3s ease;

  svg {
    color: #e53e3e;
    flex-shrink: 0;
  }

  .invalid-file-content {
    flex: 1;

    p {
      margin: 0;
      color: #c53030;
      font-size: 0.875rem;

      &.invalid-filename {
        font-weight: 500;
        margin-bottom: 0.25rem;
      }
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-1px);
  }
  20%,
  80% {
    transform: translateX(2px);
  }
  30%,
  50%,
  70% {
    transform: translateX(-3px);
  }
  40%,
  60% {
    transform: translateX(3px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.back-button {
  position: fixed;
  left: 24px;
  top: 24px;
  background-color: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(8px);
  padding: 12px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 20;

  &:hover {
    background-color: rgba(255, 255, 255, 0.18);
    transform: translateX(-2px);
  }

  &:active {
    transform: translateX(-2px) scale(0.98);
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
  }
}
.reset-success-container {
  text-align: center;
  padding: 2rem 1rem;

  .success-icon {
    margin-bottom: 1.5rem;

    svg {
      margin: 0 auto;
    }
  }

  .form-title {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    color: $st-dark-grey;
  }

  .success-description {
    color: $st-dark-grey;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }

  .button-container {
    margin-top: 1.5rem;

    .button {
      width: 100%;
      padding: 0.75rem 1rem;
    }
  }
  // Updated back to login button to match the design
  .back-to-login {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.75rem 1rem;
    color: $c-link-green; // Using the turquoise color from your design
    font-size: 0.875rem;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
    transition: color 0.2s ease;

    &:hover {
      color: darken($c-link-green, 10%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($c-link-green, 0.2);
      border-radius: 2px;
    }

    svg {
      transition: transform 0.2s ease;
      width: 14px;
      height: 14px;
    }

    &:hover svg {
      transform: translateX(-3px);
    }
  }
}
.success {
  color: #16a34a;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
}
