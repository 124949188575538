@import './_results';

// Variables
$primary-color: #4cc0ad;
$text-primary: #282b33;
$text-secondary: #6b7280;
$border-color: #e5e7eb;
$bg-empty-state: #f3f4f6;

// Dashboard Container
.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

// Dashboard Header
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .header-content {
    h1 {
      font-size: 1.875rem;
      font-weight: 700;
      color: $text-primary;
      margin: 0;
    }

    p {
      color: $text-secondary;
      margin: 0.5rem 0 0;
    }
  }

  .new-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: $primary-color;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 32px;
    cursor: pointer;
    font-weight: 500;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.9;
    }

    .plus-icon {
      width: 1rem;
      height: 1rem;
    }
  }
}

// Empty State
.empty-state-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.empty-state {
  background-color: $bg-empty-state;
  border: 1px solid #e2e2ee;
  border-radius: 0.75rem;
  padding: 3rem;
  text-align: center;
  width: 680px;

  .upload-icon {
    color: $text-secondary;
    margin-bottom: 1.5rem;
  }

  h3 {
    color: $text-primary;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  p {
    color: $text-secondary;
    margin-bottom: 1.5rem;
  }

  .upload-button {
    background-color: #f7b53d;
    color: $text-primary;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f6a51d;
    }
  }
}

// Project Item
.project-item {
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  background: white;
  overflow: hidden;

  .project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    .actions {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .delete-btn {
      margin-right: 0.5rem;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &:hover {
      background-color: #f9fafb;

      .delete-btn {
        opacity: 1;
      }
    }
  }

  .project-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .chevron-icon {
      color: $text-secondary;
      width: 1.25rem;
      height: 1.25rem;
      transition: transform 0.2s ease;
    }

    .project-name {
      font-weight: 500;
      color: $text-primary;
    }

    .package-count {
      color: $text-secondary;
      font-size: 0.875rem;
    }
  }

  .add-package-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    border: none;
    background: transparent;
    color: $text-secondary;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f3f4f6;
    }

    .plus-icon {
      width: 1rem;
      height: 1rem;
    }
  }
}

// Project Content
.project-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  border-top: 1px solid $border-color;

  &.expanded {
    max-height: 1000px; // Adjust based on content
  }
}

// Package Item
.package-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid $border-color;
  cursor: pointer;
  transition: background-color 0.2s ease;
  .delete-btn {
    opacity: 0;
  }

  &:hover {
    background-color: #f9fafb;
    .delete-btn {
      opacity: 1;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .package-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .package-icon {
      color: $text-secondary;
      width: 1.25rem;
      height: 1.25rem;
    }

    .package-details {
      h4 {
        margin: 0;
        color: $text-primary;
        font-weight: 500;
      }

      .package-meta {
        display: flex;
        gap: 1rem;
        color: $text-secondary;
        font-size: 0.875rem;
        margin-top: 0.25rem;
      }
    }
  }

  .package-metrics {
    display: flex;
    align-items: center;
    gap: 1rem;

    .duration {
      background-color: #f3f4f6;
      padding: 0.25rem 0.75rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      color: $text-secondary;
      font-weight: 500;
    }

    .price {
      color: $text-primary;
      font-weight: 500;
    }

    .status-indicator {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .status-circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-left: 0.5rem;
      }

      .duration {
        display: flex;
        align-items: center;
        background-color: #f3f4f6;
        padding: 0.25rem 0.75rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        color: $text-secondary;
        font-weight: 500;
      }
    }
  }
}

// Loading State
.loading-state {
  text-align: center;
  padding: 2rem;

  .spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid $primary-color;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 1s linear infinite;
    margin: 0 auto 1rem;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  p {
    color: $text-secondary;
  }
}

// No Packages State
.no-packages {
  text-align: center;
  padding: 1.5rem;
  color: $text-secondary;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 288px;
  height: 100vh;
  background: #2b3946;
  color: white;
  display: flex;
  flex-direction: column;

  .sidebar-header {
    height: 100px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .logo {
      width: 38px;
      height: 38px;
      margin-left: 12px;

      .logo-circle {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }

  .sidebar-nav {
    .nav-item {
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      color: white;
      text-decoration: none;
      transition: background-color 0.2s;
      position: relative;
      border: none;
      background: none;
      //width: 100%;
      text-align: left;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.active {
        background-color: #445561;

        .new-button {
          display: flex;
        }
      }

      &.feedback-button {
        margin-top: auto;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        margin: 1rem 0;
        padding-top: 1.5rem;
        width: 100%;
      }

      .nav-icon {
        width: 24px;
        height: 24px;
        margin-right: 0.75rem;
        color: white;
      }

      span {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}

// Update the main content wrapper
.content-wrapper {
  margin-left: 288px;
  padding: 1rem 2rem;
  min-height: 100vh;
  background-color: #f9fafb;
}

.modern-delete-modal {
  .modal-content {
    border: none !important;
    border-radius: 12px !important;
    box-shadow:
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    padding: 24px !important;
  }

  .modal-body {
    padding: 0 !important;
  }

  // Icon container
  .trash-icon-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    svg {
      width: 24px;
      height: 24px;
      color: #4b5563;
    }
  }

  // Text content
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin: 0 0 8px 0;
  }

  p {
    font-size: 14px;
    color: #6b7280;
    margin: 0 0 24px 0;
    line-height: 1.5;
  }

  // Button container
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button {
      min-width: 80px;
      height: 36px;
      padding: 0 16px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 6px;
      transition: all 0.2s ease;

      &.btn-cancel {
        background-color: white;
        border: 1px solid #d1d5db;
        color: #374151;

        &:hover {
          background-color: #f3f4f6;
        }
      }

      &.btn-delete {
        background-color: #dc2626;
        border: 1px solid transparent;
        color: white;

        &:hover {
          background-color: #b91c1c;
        }
      }

      &:focus {
        outline: none;
        box-shadow:
          0 0 0 2px white,
          0 0 0 4px rgba(209, 213, 219, 0.5);
      }
    }
  }
}

// Override Bootstrap's default modal styles
.modal-body {
  padding: 0 !important;
}
.modal-title {
  margin-bottom: 0;
}

.bg-danger.bg-opacity-10 {
  background-color: rgba($color: #dc3545, $alpha: 0.1);
}

.group:hover {
  .opacity-0 {
    opacity: 0;
  }

  .group-hover\:opacity-100 {
    opacity: 1;
  }
}

.hover\:bg-red-50:hover {
  background-color: #fef2f2;
}

.hover\:text-red-500:hover {
  color: #ef4444;
}

.text-red-500 {
  color: #ef4444;
}

.text-red-700 {
  color: #b91c1c;
}

.bg-red-50 {
  background-color: #fef2f2;
}
