#intro-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2b3946;

  h1,
  h2,
  h3 {
    font-family: 'Rooney Sans';
    font-weight: 800;
  }
}

#intro-page-top-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

#intro-page-top-nav .button {
  margin-left: 10px;
}

#intro-page-how-it-works-header {
  text-align: center;
}

#intro-page-content-header,
#intro-page-content-upload-quote {
  color: #fff;
  text-align: center;
  h1 {
    font-weight: 800;
    letter-spacing: -2.04px;
    margin: 0 0 8px;
    font-size: 68px;
    line-height: 1.1;
  }

  h2 {
    font-weight: 400;
    letter-spacing: -0.84px;
    margin: 0 0 24px;
    font-size: 20px;
  }
}

#intro-page-how-it-works {
  margin-top: 2rem;
}

#intro-page-how-it-works-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  .intro-page-how-it-works-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;

    .intro-page-how-it-works-icon-image {
      width: 80px;
      height: 80px;
      margin-bottom: 1rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .intro-page-how-it-works-icon-description {
      p {
        margin: 0;
        color: $white;
        font-size: 0.8rem;
      }

      span {
        color: $st-yellow;
        font-weight: 600;
      }
    }
  }
}

#intro-how-it-works-steps {
  margin-top: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -0.84px;
    margin: 0 0 24px;
    color: $white;
  }

  .intro-how-it-works-step {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    h4 {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.84px;
      margin: 0 0 8px;
      color: $st-yellow;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.84px;
      margin: 0 0 8px;
      color: $white;
    }
    div {
      display: inline-block;
      width: 50%;
    }
  }
}

#intro-page-why-choose {
  margin-top: 3rem;
  padding-bottom: 3rem;
  padding-top: 3rem;
  width: 100%;
  background-color: $st-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: inline-block;
    width: 40%;
  }
  h3 {
    color: $st-yellow;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.84px;
    margin: 0 0 24px;
  }
  #intro-page-why-choose-image {
    text-align: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  #intro-page-why-choose-text {
    text-align: left;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      padding: 0.5rem 0;
    }
  }
}

#intro-page-faq {
  margin-top: 3rem;
  h3 {
    color: $white;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.84px;
  }
  h4 {
    color: $st-yellow;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.84px;
  }
}
