@charset "UTF-8";

@import './_colours';
@import './_fonts';
@import './promo/intro';
@import './components/index';
@import './dashboard/index';

@font-face {
  font-family: sofia_pro_softlight;
  src:
    url(../fonts/sofia-pro-soft-light-webfont.woff2) format('woff2'),
    url(../fonts/sofia-pro-soft-light-webfont.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: proxima_nova;
  src: url(../fonts/proxima-nova/ProximaNova-Regular.otf) format('opentype');
}

@font-face {
  font-family: proxima_nova;
  font-weight: 100;
  src: url(../fonts/proxima-nova/ProximaNova-Light.otf) format('opentype');
}

@font-face {
  font-family: proxima_nova;
  font-weight: 700;
  src: url(../fonts/proxima-nova/ProximaNova-Semibold.otf) format('opentype');
}

@font-face {
  font-family: Rooney Sans;
  font-weight: 700;
  src: url(../fonts/RooneySans/RooneySans-Bold.ttf) format('truetype');
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: 'Rooney Sans';
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.box {
  border: 1px solid #dfdfea;
  border-radius: 10px;
  padding: 10px;
  background-color: $white;
}

.thin-line {
  width: 100%;
  height: 1px;
  background-color: $border-grey;
  transform: scaleY(0.5);
  /* Scale down to simulate thinness */
}

svg.action-menu-icon {
  cursor: pointer;
}

.action-menu {
  position: absolute;
  top: 30px;
  /* Adjust based on your layout to move it above */
  right: 12px;
  min-width: 130px;
  padding: 0px;
  z-index: 10;
  /* Ensures it appears above other elements */

  .action-menu-item {
    cursor: pointer;
    padding-bottom: 4px;
    padding: 8px;
  }

  .action-menu-item:not(:last-child) {
    border-bottom: 1px solid $border-grey;
    padding-bottom: 8px;
  }
}

.green {
  color: $c-link-green;
}

.yellow {
  color: $st-yellow;
}

.button {
  padding: 10px 28px;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  border: none;
}

.yellow-button {
  background-color: $st-yellow;
  color: #000;
}

.green-button {
  background-color: $c-link-green;
  color: $white;
}

.empty-button {
  background-color: transparent;
  color: $c-link-green;
  border: 2px solid $c-link-green;
}

label {
  color: $label-text;
  font-weight: 600;
  font-size: 14px;
}

#public-layout {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: $st-dark-grey;
}

footer {
  text-align: center;
  padding: 2rem 0;
  background-color: $c-link-dark-grey;

  a {
    color: $white;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    padding: 0 10px;
  }

  a:hover {
    text-decoration: underline;
  }

  .footer-content {
    background-color: $st-dark-grey;
  }

  .footer-logo {
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    padding-left: 5px;
  }
}

.error {
  color: $error-red;
}

.load-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  /* Horizontal center */
  align-items: center;
}

.MuiSvgIcon-root {
  vertical-align: middle;
}

.help-support-page {
  background-color: $st-dark-grey;
  min-height: 100vh;
  height: 100%;
  color: $white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  .content-wrapper {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1.5rem;
    height: 100%;
    background-color: $st-dark-grey !important;
  }

  .back-button-container {
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1;

    .back-button {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      padding: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content-section {
    padding-top: 5rem;
    margin: 0 auto;
  }

  h1 {
    color: $white;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 3rem;
    font-family: 'Rooney Sans';
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  }

  h2 {
    color: $white;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 2rem;
    font-family: 'Rooney Sans';
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.1);
  }

  .faq-section {
    margin-bottom: 3rem;
  }

  .faq-item {
    padding: 2rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:last-child {
      border-bottom: none;
    }

    h3 {
      color: $white;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 1rem;
      font-family: 'Rooney Sans';
      text-shadow: 0 0 6px rgba(255, 255, 255, 0.1);
    }

    p {
      color: rgba(255, 255, 255, 0.65); // Lighter gray color
      line-height: 1.6;
      font-family: 'proxima_nova', 'sofia_pro_softlight', sans-serif;
      font-size: 1rem;
    }
  }

  .contact-section {
    margin-top: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 2rem;

    p {
      font-family: 'proxima_nova', 'sofia_pro_softlight', sans-serif;
      font-size: 1rem;
      line-height: 1.6;
      color: rgba(255, 255, 255, 0.65); // Matching the FAQ answer color
    }

    a {
      color: $c-link-green;
      transition: color 0.2s ease;

      &:hover {
        color: lighten($c-link-green, 10%);
        text-decoration: underline;
      }
    }
  }
}
