.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.modal {
  .loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 300px;
  }
  z-index: 1001;
  position: fixed;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(226, 226, 238);
  border-radius: 0.5rem;
  width: 600px;
  padding: 1rem;

  .modal-header {
    border-bottom: none;
  }

  .close-icon {
    background: none; /* Remove any background */
    padding: 0;
    border: none; /* Ensure no border is applied */
    cursor: pointer;
    float: right;
  }

  #project-data {
    padding-top: 1rem;
    .project-data-item {
      margin-bottom: 0.5rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        width: 98%;
      }
    }
  }

  .modal-footer {
    border-top: none;
    text-align: center;
    .modal-footer-buttons {
      display: inline-flex;
      justify-content: space-between;
      gap: 1rem;
    }
  }
}
