@font-face {
  font-family: RooneySans;
  src:
    url(../fonts/RooneySans/RooneySans-Regular.woff2) format('woff2'),
    url(../fonts/RooneySans/RooneySans-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: RooneySans;
  src:
    url(../fonts/RooneySans/RooneySans-RegularItalic.woff2) format('woff2'),
    url(../fonts/RooneySans/RooneySans-RegularItalic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: RooneySans;
  src:
    url(../fonts/RooneySans/RooneySans-Bold.woff2) format('woff2'),
    url(../fonts/RooneySans/RooneySans-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: RooneySans;
  src:
    url(../fonts/RooneySans/RooneySans-BoldItalic.woff2) format('woff2'),
    url(../fonts/RooneySans/RooneySans-BoldItalic.woff) format('woff');
  font-weight: 700;
  font-style: italic;
}
