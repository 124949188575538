$st-yellow: #f4b82e;
$st-blue: #08202a;
$st-dark-grey: #2b3946;
$c-link-green: #4cc0ad;
$c-link-dark-grey: #2b3946;
$white: #fff;
$label-text: #1a1b1f;
$input-border: #c6c5de;
$background-light-grey: #f6f6f9;
$error-red: #ba2e2e;
$border-grey: #dfdfea;
