.analysis-wrapper {
  display: flex;
  gap: 2rem;
  padding: 1.5rem;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.analysis-container {
  min-height: 100vh;
  flex: 1;
  padding: 0;
  background-color: transparent;

  .header {
    margin-bottom: 2.5rem;

    .title {
      font-size: 1.75rem;
      font-weight: 700;
      color: #222;
      margin-bottom: 1rem;
      letter-spacing: -0.015em;
    }

    .project-info {
      .metadata {
        display: flex;
        gap: 2rem;
        color: #555;
        font-size: 0.9rem;

        .info-item {
          display: flex;
          align-items: center;

          strong {
            font-weight: 600;
            margin-right: 0.5rem;
          }

          &:not(:last-child):after {
            content: '';
            margin-left: 2rem;
          }
        }
      }
    }
  }

  .project-analysis-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #222;
    margin: 2.5rem 0 1.5rem;
    letter-spacing: -0.01em;
  }

  .analysis-section {
    background: white;
    border-radius: 0.625rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    margin-bottom: 1.25rem;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.03);
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
    }

    .section-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 1.5rem;
      border: none;
      background: none;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f9fafc;
      }

      .section-title {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .title-text {
          font-weight: 600;
          color: #222;
          font-size: 1.125rem;
        }

        .item-count {
          font-weight: normal;
          font-size: 0.875rem;
          color: #666;
          background: #f0f3f8;
          padding: 0.25rem 0.5rem;
          border-radius: 1rem;
        }
      }

      .chevron {
        color: #555;
        transition: transform 0.2s;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    // List styling to be added to your existing SCSS

    .section-content {
      padding: 0 1.5rem 1.5rem;

      // Styling for all lists
      .MuiList-root,
      ul,
      ol {
        padding: 0;
        margin: 0 0 1rem 0;
        list-style: none;

        // Individual list items
        .MuiListItem-root,
        li {
          padding: 0.875rem 1rem;
          margin-bottom: 0.75rem;
          background-color: #f8f9fa;
          border-radius: 0.5rem;
          border-left: 3px solid #d1d5db;
          display: flex;
          align-items: flex-start;
          transition: background-color 0.2s ease;
          position: relative;

          &:hover {
            background-color: #f1f3f5;
          }

          // Add bullet indicator for all list items
          //&::before {
          //  content: '•';
          //  color: #6b7280;
          //  font-size: 1.25rem;
          //  margin-right: 0.75rem;
          //  flex-shrink: 0;
          //}

          &:last-child {
            margin-bottom: 0;
          }

          // List item text
          .MuiListItemText-root {
            margin: 0;

            .MuiTypography-root {
              color: #333;
              font-size: 0.95rem;
              line-height: 1.5;
            }
          }
        }
      }

      // For regular HTML lists without Material UI
      ul,
      ol {
        li {
          padding-left: 2rem;

          &::before {
            position: absolute;
            left: 0.75rem;
          }
        }
      }

      // Special case for when list items are direct children in a container
      .list-container,
      .findings-container,
      [class*='-list-container'] {
        .list-item,
        .finding-item,
        [class*='-item'] {
          padding: 0.875rem 1rem 0.875rem 2rem;
          margin-bottom: 0.75rem;
          background-color: #f8f9fa;
          border-radius: 0.5rem;
          border-left: 3px solid #d1d5db;
          position: relative;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #f1f3f5;
          }

          //&::before {
          //  content: '•';
          //  color: #6b7280;
          //  position: absolute;
          //  left: 0.75rem;
          //  font-size: 1.25rem;
          //}

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      // Tables remain the same as before
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        th {
          text-align: left;
          padding: 0.75rem 1rem;
          font-weight: 600;
          color: #444;
          background-color: #f7f9fc;
          border-bottom: 1px solid #e5e9f2;
          font-size: 0.875rem;

          &:first-child {
            border-top-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
          }
        }

        td {
          padding: 0.875rem 1rem;
          border-bottom: 1px solid #edf1f7;
          color: #333;
          font-size: 0.9rem;
        }

        tr:last-child td {
          border-bottom: none;

          &:first-child {
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-bottom-right-radius: 0.5rem;
          }
        }

        tbody tr:nth-child(even) {
          background-color: #fafbfd;
        }

        tbody tr:hover {
          background-color: #f5f7fc;
        }
      }

      // Section headings
      .section-subheading {
        font-size: 1.125rem;
        font-weight: 600;
        color: #333;
        margin: 1.5rem 0 1rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #edf1f7;
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;

    .spinner {
      width: 2.5rem;
      height: 2.5rem;
      border: 3px solid #eef1f7;
      border-top-color: #4a6cf7;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }

  .error {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #e53e3e;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border: 1px solid #fee2e2;
    font-weight: 500;
  }
}

.side-panel {
  width: 340px;
  flex-shrink: 0;
  margin-top: 8.8rem;

  .panel-content {
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    position: sticky;
    top: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.03);

    .panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.75rem;
      padding-bottom: 1.25rem;
      border-bottom: 1px solid #eef1f7;

      .panel-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: #222;
        margin: 0;
        letter-spacing: -0.01em;
      }

      .export-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 0.75rem;
        background-color: #f0f5ff;
        border: 1px solid #e0eaff;
        border-radius: 0.375rem;
        color: #3366ff;
        font-size: 0.875rem;
        font-weight: 500;
        transition: all 0.2s ease;

        &:hover {
          background-color: #e0eaff;
          border-color: #c8d9ff;
        }

        &:active {
          background-color: #d0e0ff;
          transform: translateY(1px);
        }
      }
    }

    .subcontractors-section {
      .subcontractor-card {
        padding: 1.25rem;
        background: #f9fafd;
        border-radius: 0.5rem;
        margin-bottom: 1.25rem;
        border: 1px solid #eef1f7;

        &:last-child {
          margin-bottom: 0;
        }

        .subcontractor-header {
          display: flex;
          align-items: center;
          gap: 0.625rem;
          margin-bottom: 1rem;
          padding-bottom: 0.875rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);

          svg {
            color: #4a6cf7;
          }

          .subcontractor-name {
            font-size: 0.95rem;
            font-weight: 600;
            color: #333;
            margin: 0;
          }
        }

        .files-list {
          display: flex;
          flex-direction: column;
          gap: 0.625rem;

          .file-item {
            display: flex;
            align-items: center;
            gap: 0.625rem;
            padding: 0.625rem 0.75rem;
            background: white;
            border-radius: 0.375rem;
            border: 1px solid rgba(0, 0, 0, 0.05);
            position: relative;
            transition: all 0.2s ease;

            &:hover {
              background-color: #fafbfe;
              border-color: rgba(74, 108, 247, 0.2);
            }

            svg {
              color: #5a6171;
              flex-shrink: 0;
            }

            .file-name {
              font-size: 0.8125rem;
              color: #444;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: help;

              &:hover::after {
                content: attr(title);
                position: absolute;
                left: 0;
                bottom: calc(100% + 8px);
                background: #333;
                color: white;
                padding: 0.625rem;
                border-radius: 0.25rem;
                font-size: 0.8125rem;
                white-space: normal;
                max-width: 280px;
                z-index: 1000;
                box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
                line-height: 1.4;
              }
            }
          }
        }
      }
    }
  }
}

// Status alert styling
.alert {
  border-radius: 0.625rem;
  border: 1px solid transparent;

  &.alert-info {
    background-color: #ebf5ff;
    border-color: #cce5ff;
    color: #0057b8;
  }

  &.alert-warning {
    background-color: #fff8e6;
    border-color: #ffeeba;
    color: #856404;
  }

  &.alert-danger {
    background-color: #feebef;
    border-color: #f8d7da;
    color: #cc3057;
  }
}

// Responsive design
@media (max-width: 1024px) {
  .analysis-wrapper {
    flex-direction: column;
  }

  .side-panel {
    width: 100%;
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
